<template>
  <v-layout
    v-if="isAdmin()"
    wrap>
    <v-flex md12>
      <material-card
        :title="'Media'"
        color="green"
        flat
        full-width
      >
        <v-expansion-panel popout>
          <v-expansion-panel-content
            v-for="(item, i) in 3"
            :key="i"
          >
            <template v-slot:header>
              <div v-if="item == 1 && isSuperAdmin()">
                {{ $t('generalConfiguration.serverMedia') }}
              </div>
              <div v-else-if="item == 2">
                {{ $t('generalConfiguration.mediaProject') }}
              </div>
            </template>
            <template>
              <div v-if="item == 1 && isSuperAdmin()">
                <v-container grid-list-xl>
                  <v-form>
                    <v-layout
                      wrap
                      layout
                    >
                      <v-flex
                        xs12
                        sm12
                        md12
                      >
                        <v-text-field
                          :rules="[ruleRequiredValue]"
                          v-model="mediaServer.mediaDomain"
                          :label="$t('generalConfiguration.mediaDomain')"
                          class="required"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          :rules="[ruleRequiredValue]"
                          v-model="mediaServer.mediaUsername"
                          :label="$t('generalConfiguration.mediaUsername')"
                          class="required"
                        />
                      </v-flex>
                      <v-flex
                        v-if="!mediaServer.mediaPasswordExists"
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          :rules="[ruleRequiredValue]"
                          v-model="mediaServer.mediaPassword"
                          :label="$t('generalConfiguration.mediaPassword')"
                          type="password"
                          class="required"
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                  <v-spacer/>
                  <v-btn
                    color="success darken-1"
                    small
                    float-right
                    @click="saveConfigSMTP"
                  >{{ $t('common.save') }}</v-btn>
                  <v-btn
                    v-if="mediaServer.mediaPasswordExists"
                    color="warning darken-1"
                    small
                    @click="onShowModalChangePasswordSystemConfig"
                  >{{ $t('common.changePassword') }}</v-btn>
                </v-container>
              </div>
              <div v-else-if="item == 2">
                <v-container grid-list-xl>
                  <v-form>
                    <v-layout
                      wrap
                      layout
                    >
                      <v-flex
                        v-if="isSuperAdmin()"
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="mediaProject.mediaProjectName"
                          :label="$t('generalConfiguration.mediaProjectName')"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm6
                        md6
                      >
                        <v-text-field
                          v-model="mediaProject.mediaPageSize"
                          :label="$t('generalConfiguration.mediaPageSize')"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        sm12
                        md12
                      >
                        <v-textarea
                          v-model="mediaProject.mediaDescription"
                          :label="$t('generalConfiguration.mediaDescription')"
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                  <v-spacer/>
                  <v-btn
                    color="success darken-1"
                    small
                    float-right
                    @click="saveSMTPProject"
                  >{{ $t('common.save') }}</v-btn>
                </v-container>
              </div>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </material-card>
    </v-flex>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
    <changePasswordModal ref="changePasswordModal" />
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import SysRoleType from 'enum/sysRoleType'
import ToastType from 'enum/toastType'
import ChangePasswordModal from './ChangePasswordModal'
import ServiceType from 'enum/serviceType'
export default {
  components: {
    ChangePasswordModal
  },
  data: () => ({
    valid: false,
    mediaProject: {
      mediaProjectName: null,
      mediaPageSize: null,
      mediaDescription: null
    },
    mediaServer: {
      mediaDomain: null,
      mediaUsername: null,
      mediaPassword: null,
      mediaPasswordExists: false
    },
    SysRoleType: {
      ADMIN: SysRoleType.ADMIN
    },
    entity: {},
    allProjects: []
  }),
  computed: {
    ...mapGetters([
      'GET_MEDIA_CONFIG_DATA',
      'GET_MEDIA_PROJECT_DATA',
      'GET_CURRENT_ACCOUNT',
      'GET_SNACK_BAR'
    ])
  },
  watch: {
    GET_MEDIA_CONFIG_DATA: function () {
      let res = this.GET_MEDIA_CONFIG_DATA
      this.mediaServer.mediaDomain = res.media_domain ? res.media_domain : ''
      this.mediaServer.mediaUsername = res.media_username && res.media_username
      // this.mediaServer.mediaPassword = ''
      this.mediaServer.mediaPasswordExists = res.media_password_exists
    },
    GET_MEDIA_PROJECT_DATA: function () {
      let res = this.GET_MEDIA_PROJECT_DATA
      this.mediaProject.mediaProjectName = res.name ? res.name : ''
      this.mediaProject.mediaPageSize = res.pagination ? res.pagination : null
      this.mediaProject.mediaDescription = res.description ? res.description : ''
    }
  },
  created () {
    this.GET_MEDIA_CONFIG()
    this.getMediaProject()
  },
  methods: {
    /**
     * Show modal config password for system service
     */
    onShowModalChangePasswordSystemConfig: function () {
      this.$refs.changePasswordModal.onShowModal(ServiceType.MEDIA)
    },
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Is entity type
     */
    isEntityType: function (entityType) {
      return functionUtils.isEntityType(entityType)
    },
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Save SMTP Project
     */
    saveSMTPProject: function () {
      let data = {
        project_name: this.mediaProject.mediaProjectName,
        page_size: this.mediaProject.mediaPageSize,
        description: this.mediaProject.mediaDescription
      }
      this.PUSH_MEDIA_PROJECT(data)
    },
    /**
     * Save config SMTP
     */
    validateSMTPConfig: function () {
      let validateResult = 'OK'
      for (let key in this.mediaServer) {
        if (functionUtils.isEmptyString(this.mediaServer[key])) {
          validateResult = this.$t('generalConfiguration.' + key)
          break
        }
      }
      return validateResult
    },
    saveConfigSMTP: function () {
      let data = {
        media_password: this.mediaServer.mediaPassword,
        media_username: this.mediaServer.mediaUsername,
        media_domain: this.mediaServer.mediaDomain
      }
      this.PUSH_MEDIA_CONFIG(data).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.GET_MEDIA_CONFIG()
          this.getMediaProject()
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }
      )
    },
    /**
     * Get SMTP project
     */
    getMediaProject: function () {
      this.is_loading = true
      this.GET_MEDIA_PROJECT()
    },
    ...mapActions([
      'GET_MEDIA_CONFIG',
      'PUSH_MEDIA_CONFIG',
      'GET_MEDIA_PROJECT',
      'PUSH_MEDIA_PROJECT',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style lang="scss" scoped>
/deep/.v-input--selection-controls.v-input .v-label {
  margin-bottom: 0px !important;
}
</style>
